Plugin = require './Plugin'
map = require 'lodash/map'

class PostPlugin extends Plugin
    constructor: () ->
        super()
        @framedStyles = []

    getName: () -> "post"

    addFramedStyle: (style) ->
        @framedStyles.push(style)
        @

    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createButton({
            label: "quote"
            clickCallback: (bbCodeEdit) ->
                bbCodeEdit.wrapSelectionWithTag("quote", {
                    author: prompt("Entrez le nom de l'auteur", "")
                })
        }))

        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({label: 'framed'},
            map(@framedStyles, (style) -> {
                label: style
                clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag("framed", {style})
            })
        ))

module.exports = PostPlugin