module.exports = {
    ToolbarBuilder: require './ToolbarBuilder'
    Plugin: require './Plugin/Plugin'
    BbCodeEdit: require './BbCodeEdit'
    plugins: {
        EmailPlugin: require './Plugin/EmailPlugin'
        ListPlugin: require './Plugin/ListPlugin'
        ImagePlugin: require('genpages-reason/component/bbcode/BBCode_ImagePlugin.bs.js').make
        PostPlugin: require './Plugin/PostPlugin'
        TablePlugin: require './Plugin/TablePlugin'
        TextPlugin: require './Plugin/TextPlugin'
        TitlePlugin: require './Plugin/TitlePlugin'
        UrlPlugin: require './Plugin/UrlPlugin'
        VideoPlugin: require './Plugin/VideoPlugin'
    }
}