jq = require 'jquery'
Plugin = require './Plugin'

class TablePlugin extends Plugin
    getName: () -> "table"
    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({label: 'Table', icon: 'fa fa-table'}, [
            {
                icon: "fa fa-table"
                label: "Table"
                clickCallback: (bbCodeEdit) ->
                    table =
                    """
                    [table class="table-bordered"]
                        [thead]
                            [tr][th]Colonne 1[/th][th]Colonne 2[/th][/tr]
                        [/thead]
                        [tbody]
                            [tr][td]Colonne 1[/td][td]Colonne 2[/td][/tr]
                            [tr][td]Colonne 1[/td][td]Colonne 2[/td][/tr]
                            [tr][td]Colonne 1[/td][td]Colonne 2[/td][/tr]
                        [/tbody]
                    [/table]
                    """

                    bbCodeEdit.insertText(table)
            }
            {
                icon: "fa fa-list"
                label: "Ligne de tableau"
                clickCallback: (bbCodeEdit) ->
                    row = "[tr][td]Colonne 1[/td][td]Colonne 2[/td][/tr]"
                    bbCodeEdit.insertText(row)
            }
            {
                icon: "fa fa-th"
                label: "Cellule de tableau"
                clickCallback: (bbCodeEdit) ->
                    bbCodeEdit.wrapSelectionWithTag("td")
            }
            {
                icon: "fa fa-info-circle"
                label: "Infos"
                clickCallback: (bbCodeEdit) ->
                    modal =
                    """
                    <div class="modal fade">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 class="modal-title">Exemple d'utilisation des tableaux en bbcode</h4>
                                </div>
                                <div class="modal-body">
                                    <h2>Tableau</h2>
                                    <p>
                                        Dans le bbcode un tableau se compose avec les balises <strong>[table]</strong>, <strong>[thead]</strong>, <strong>[tbody]</strong>, <strong>[tr]</strong>, <strong>[th]</strong>, <strong>[td]</strong>
                                    </p>
                                    <p>
                                        La balise <strong>[table]</strong> peut contenir soit <strong>[thead]</strong> et <strong>[tbody]</strong>, soit directement <strong>[tr]</strong><br/>
                                        Les balises <strong>[thead]</strong> et <strong>[tbody]</strong> doivent contenir <strong>[tr]</strong><br />
                                        La balise <strong>[tr]</strong> doit contenir <strong>[th]</strong> ou <strong>[td]</strong>
                                    </p>
                                    <p><strong>[tr]</strong> représente une ligne, tandis que <strong>[td]</strong> représente une cellule, (<strong>[th]</strong> représente une cellule d'entête</p>
                                    <p><strong>[thead]</strong> représente le header du tableau tandis que <strong>[tbody]</strong> représente le corps du tableau.</p>

                                    <h2>Style de tableau</h2>
                                    <p>La balise <strong>[table]</strong> peut contenir l'attribut <code>class</code> pour pouvoir choisir un style au tableau.</p>
                                    <p>Les styles les plus communs sont <code>table-bordered</code>, <code>table-striped</code>, <code>table-condensed</code> et <code>table-hover</code></p>

                                    <h3>Exemple de table par default</h3>
                                    <pre>
[table]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Exemple de table-bordered</h3>
                                    <pre>
[table class="table-bordered"]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table table-bordered">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Exemple de table-condensed</h3>
                                    <pre>
[table class="table-condensed"]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table table-condensed">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Exemple de table-striped</h3>
                                    <pre>
[table class="table-striped"]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table table-striped">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3>Exemple de table-hover</h3>
                                    <pre>
[table class="table-hover"]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table table-hover">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Exemple de table-bordered, table-condensed et table-striped</h3>
                                    <p>On peut aussi mixer les différents styles</p>
                                    <pre>
[table class="table-bordered table-condensed table-striped"]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table table-bordered table-condensed table-striped">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <h3>Exemple de text-center</h3>
                                    <p>On peut aussi centrer les éléments du tableau avec la class text-center</p>
                                    <pre>
[table class="text-center"]
    [thead]
        [tr][th]Colonne 1[/th][th]Colonne 2[/th][th]Colonne 2[/th][/tr]
    [/thead]
    [tbody]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
        [tr][td]Colonne 1[/td][td]Colonne 2[/td][td]Colonne 3[/td][/tr]
    [/tbody]
[/table]
                                    </pre>
                                    <div class="bbcode-preview bbcode-type-default">
                                        <table class="decoda-table text-center">
                                            <thead>
                                                <tr><th>Colonne 1</th><th>Colonne 2</th><th>Colonne 2</th></tr>
                                            </thead>
                                            <tbody>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                                <tr><td>Colonne 1</td><td>Colonne 2</td><td>Colonne 2</td></tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    """
                    $modal = jq(modal)
                    $modal.modal()
            }
        ]))

module.exports = TablePlugin