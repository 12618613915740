Plugin = require './Plugin'

class TextPlugin extends Plugin
    getName: () -> "text"
    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createGroup([
            {icon: "fa fa-bold", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('b')}
            {icon: "fa fa-italic", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('i')}
            {icon: "fa fa-underline", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('u')}
            {icon: "fa fa-paragraph", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('p')}
            {label: "br", clickCallback: (bbCodeEdit) -> bbCodeEdit.insertTagAutoclosed('br')}
            {label: "hr", clickCallback: (bbCodeEdit) -> bbCodeEdit.insertTagAutoclosed('hr')}
            {label: "small", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('small')}
            {icon: "fa fa-align-center", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('textCenter')}
        ]))

module.exports = TextPlugin