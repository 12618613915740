// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Jquery from "../../vendor/Jquery.bs.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function toolbar(bbcode) {
  return Belt_Option.getExn(Jquery.get(bbcode.$toolbar, 0));
}

function insertTagAutoclosed(bbcode, tag, params) {
  bbcode.insertTagAutoclosed(tag, Js_dict.fromList(params));
  return /* () */0;
}

export {
  toolbar ,
  insertTagAutoclosed ,
  
}
/* Jquery Not a pure module */
