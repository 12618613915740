// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Js_option from "bs-platform/lib/es6/js_option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function flatDef(def, option) {
  if (option !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(option));
  } else {
    return def;
  }
}

function lazyDef(fdef, option) {
  if (option !== undefined) {
    return Caml_option.valFromOption(option);
  } else {
    return Curry._1(fdef, /* () */0);
  }
}

function map(f, x) {
  if (x !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(x)));
  }
  
}

function flatMap(f, x) {
  var match = map(f, x);
  if (match !== undefined) {
    var match$1 = Caml_option.valFromOption(match);
    if (match$1 !== undefined) {
      return Caml_option.some(Caml_option.valFromOption(match$1));
    } else {
      return ;
    }
  }
  
}

function flatMap2(f, x) {
  if (x !== undefined) {
    var match = Curry._1(f, Caml_option.valFromOption(x));
    var match$1 = match[0];
    if (match$1 !== undefined) {
      var match$2 = match[1];
      if (match$2 !== undefined) {
        return /* tuple */[
                Caml_option.valFromOption(match$1),
                Caml_option.valFromOption(match$2)
              ];
      } else {
        return ;
      }
    } else {
      return ;
    }
  }
  
}

function flatMap3(f, x) {
  if (x !== undefined) {
    var match = Curry._1(f, Caml_option.valFromOption(x));
    var match$1 = match[0];
    if (match$1 !== undefined) {
      var match$2 = match[1];
      if (match$2 !== undefined) {
        var match$3 = match[2];
        if (match$3 !== undefined) {
          return /* tuple */[
                  Caml_option.valFromOption(match$1),
                  Caml_option.valFromOption(match$2),
                  Caml_option.valFromOption(match$3)
                ];
        } else {
          return ;
        }
      } else {
        return ;
      }
    } else {
      return ;
    }
  }
  
}

function defMap(f, x) {
  if (x !== undefined) {
    return x;
  } else {
    return Curry._1(f, /* () */0);
  }
}

function let_(a, b) {
  if (a !== undefined) {
    return Curry._1(b, Caml_option.valFromOption(a));
  }
  
}

var Opt = {
  let_: let_
};

function let_$1(x, f) {
  return map(f, x);
}

var $$Map = {
  let_: let_$1
};

function let_$2(x, f) {
  return flatMap(f, x);
}

var FlatMap = {
  let_: let_$2
};

var def = Js_option.getWithDefault;

export {
  def ,
  flatDef ,
  lazyDef ,
  map ,
  flatMap ,
  flatMap2 ,
  flatMap3 ,
  defMap ,
  Opt ,
  $$Map ,
  FlatMap ,
  
}
/* No side effect */
