jq = require 'jquery'
_ = require 'lodash'

# Utility to select a dom element via jquery with closest method, find method, and jquery method.
#
class SelectorHelper
    sharedInstance = null

    @get = () ->
        sharedInstance = new SelectorHelper() unless sharedInstance?
        sharedInstance

    constructor: () ->
        @regex = /((closest|find|self)(\((.+)\))?:)?(.*)/

# Select the dom element
#
# @param [String] selector
# @param [Object] containers
# @return jq
#
# @example Select via jquery
#   SelectorHelper.get().select("#message-1")
#
# @example Select via closest (or find)
#   SelectorHelper.get().select("closest:li", {default: $el})
#   SelectorHelper.get().select("find:li", {default: $el})
#
# @example Select via closest (or find) with container name
#   SelectorHelper.get().select("closest(container):li", {default: $container, container: $container, btn: $btn})
#   SelectorHelper.get().select("find(btn):li.message", {default: $container, container: $container, btn: $btn})
#
    select: (selector, containers = {}) ->
        matches = @regex.exec(selector)

        method = @getMethod(matches)
        containerName = @getContainerName(matches)
        select = @getSelect(matches)

        return switch method
            when 'closest'
                @getContainer(containerName, containers).closest(select)
            when 'find'
                @getContainer(containerName, containers).find(select)
            when 'self'
                @getContainer(containerName, containers)
            else
                jq(select)

    getMethod: (matches) -> if matches[2] then matches[2] else 'jq'
    getContainerName: (matches) -> if matches[4] then matches[4] else 'default'
    getSelect: (matches) -> matches[5]

    getContainer: (containerName, containers) ->
        if !containers[containerName]?
            throw "Error: #{containerName} does not exist in container #{JSON.stringify(containers)}"
        else
            containers[containerName]

module.exports = SelectorHelper