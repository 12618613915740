Plugin = require './Plugin'

class UrlPlugin extends Plugin
    getName: () -> "url"
    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({label: 'Liens'}, [
            {
                icon: "fa fa-link"
                label: "Lien simple"
                clickCallback: (bbCodeEdit) ->
                    url = prompt("Entrez l'url du lien", "http://www.google.com")
                    bbCodeEdit.wrapSelectionWithTag('url', {
                        '_default': url
                        'target': '_blank'
                    })
            }
        ]))

module.exports = UrlPlugin