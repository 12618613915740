// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Caml_chrome_debugger from "bs-platform/lib/es6/caml_chrome_debugger.js";

function then_(prim, prim$1) {
  return prim$1.then(Curry.__1(prim));
}

function reject(prim) {
  return Promise.reject(prim);
}

function resolve(prim) {
  return Promise.resolve(prim);
}

function resolveOk(v) {
  return Promise.resolve(/* Ok */Caml_chrome_debugger.variant("Ok", 0, [v]));
}

function flatMap(promise, callback) {
  return promise.then(Curry.__1(callback));
}

function map(promise, callback) {
  return promise.then((function (x) {
                return Promise.resolve(Curry._1(callback, x));
              }));
}

function tap(promise, callback) {
  return map(promise, (function (x) {
                Curry._1(callback, x);
                return x;
              }));
}

function flatMapOk(promise, callback) {
  return promise.then((function (result) {
                if (result.tag) {
                  return Promise.resolve(/* Error */Caml_chrome_debugger.variant("Error", 1, [result[0]]));
                } else {
                  return Curry._1(callback, result[0]);
                }
              }));
}

function mapOk(promise, callback) {
  return flatMapOk(promise, (function (result) {
                return Promise.resolve(Curry._1(callback, result));
              }));
}

function flatMapError(promise, callback) {
  return promise.then((function (result) {
                if (result.tag) {
                  return Curry._1(callback, result[0]);
                } else {
                  return Promise.resolve(/* Ok */Caml_chrome_debugger.variant("Ok", 0, [result[0]]));
                }
              }));
}

function mapError(promise, callback) {
  return flatMapError(promise, (function (error) {
                return Promise.resolve(Curry._1(callback, error));
              }));
}

function errorIfNone(promise, error) {
  return mapOk(promise, (function (param) {
                if (param !== undefined) {
                  return /* Ok */Caml_chrome_debugger.variant("Ok", 0, [Caml_option.valFromOption(param)]);
                } else {
                  return /* Error */Caml_chrome_debugger.variant("Error", 1, [error]);
                }
              }));
}

function rejectIfNone(promise, exn) {
  return promise.then((function (value) {
                if (value !== undefined) {
                  return Promise.resolve(Caml_option.valFromOption(value));
                } else {
                  return Promise.reject(exn);
                }
              }));
}

function flatCatch(promise, callback) {
  return promise.catch(Curry.__1(callback));
}

function $$catch(promise, callback) {
  return promise.catch((function (a) {
                return Promise.resolve(Curry._1(callback, a));
              }));
}

function $$finally(promise, callback) {
  return promise.finally((function () {
                return Curry._1(callback, /* () */0);
              }));
}

function convertResult(promise) {
  return new Promise((function (resolve, param) {
                map(promise, (function (v) {
                        return resolve(/* Ok */Caml_chrome_debugger.variant("Ok", 0, [v]));
                      }));
                promise.catch((function (e) {
                        return resolve(/* Error */Caml_chrome_debugger.variant("Error", 1, [/* `exn */Caml_chrome_debugger.polyVar("exn", [
                                          5049499,
                                          e
                                        ])]));
                      }));
                return /* () */0;
              }));
}

function fromResult(promise, mapError) {
  return map(promise, (function (param) {
                if (param.tag) {
                  throw Curry._1(mapError, param[0]);
                } else {
                  return param[0];
                }
              }));
}

var Op = {
  $great$pipe$eq: map,
  $great$great$eq: flatMap,
  $great$great$great: tap,
  $great$pipe$neg: $$catch,
  $great$great$pipe$neg: flatCatch
};

function delay(ms) {
  return new Promise((function (resolve, param) {
                setTimeout((function (param) {
                        return resolve(/* () */0);
                      }), ms);
                return /* () */0;
              }));
}

var unit = /* () */0;

export {
  then_ ,
  reject ,
  resolve ,
  resolveOk ,
  flatMap ,
  map ,
  tap ,
  flatMapOk ,
  mapOk ,
  flatMapError ,
  mapError ,
  errorIfNone ,
  rejectIfNone ,
  flatCatch ,
  $$catch ,
  $$finally ,
  convertResult ,
  fromResult ,
  Op ,
  unit ,
  delay ,
  
}
/* No side effect */
