jq = require 'jquery'

class ToolbarBuilder
    constructor: (@bbCodeEdit) ->
        @btnClass = "btn btn-bbcode"
        @btnGroupClass = "btn-group btn-group-bbcode"


    createDropdown: (opts, buttonDefinitions) ->
        $group = jq('<div role="group"></div>').addClass(@btnGroupClass)

        $mainButton = jq('<div data-toggle="dropdown"></div>')

        $mainButton.addClass(@btnClass)
        $mainButton.addClass('dropdown-toggle')
        $mainButton.addClass(if opts.cls? then opts.cls else 'btn-default')

        $mainButton.append(jq('<i></i>').addClass(opts.icon)) if opts.icon?
        $mainButton.append(jq('<span>&nbsp;</span>'))
        $mainButton.append(jq("<span>#{opts.label}</span>")) if opts.label?
        $mainButton.html($mainButton.html() + '&nbsp;<span class="caret"></span>')

        $ul = jq('<ul class="dropdown-menu" role="menu">')
        for buttonDef in buttonDefinitions
            $li = @createListItem(buttonDef)
            $ul.append($li)

        $group.append($mainButton)
        $group.append($ul)

    createGroup: (buttonDefinitions) ->
        $group = jq('<div role="group"></div>').addClass(@btnGroupClass)

        for buttonDef in buttonDefinitions
            $button = @createButton(buttonDef)
            $group.append($button)

        return $group

    createListItem: (def) ->
        $li = jq('<li></li>')
        $a = jq('<a>')
        $li.append($a)

        $a.append(jq('<i></i>').addClass(def.icon)) if def.icon?
        $a.append(jq("<span>#{def.label}</span>")) if def.label

        if def.clickCallback?
            $a.on "click", () =>
                def.clickCallback(@bbCodeEdit, $a)

        return $li

    createButton: (def) ->
        $btn = jq('<div></div>')

        $btn.addClass(@btnClass)
        $btn.addClass(if def.cls? then def.cls else 'btn-default')

        $btn.append(jq('<i></i>').addClass(def.icon)) if def.icon?
        $btn.append(jq('<span></span>').html(def.label)) if def.label

        if def.clickCallback?
            $btn.on "click", () =>
                def.clickCallback(@bbCodeEdit, $btn)

        return $btn

module.exports = ToolbarBuilder