// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var nbsp = "\u00A0";

var laquo = "\u00AB";

var raquo = "\u00BB";

var mldr = "\u2026";

var times = "\u00D7";

var nbspElement = nbsp;

var laquoElement = laquo;

var raquoElement = raquo;

var mldrElement = mldr;

var timesElement = times;

export {
  nbsp ,
  nbspElement ,
  laquo ,
  laquoElement ,
  raquo ,
  raquoElement ,
  mldr ,
  mldrElement ,
  times ,
  timesElement ,
  
}
/* No side effect */
