// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Utils_option from "../utils/Utils_option.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/lib/es6/src/helper.bs.js";

function defaultMessage(param) {
  return "Ne doit pas être vide.";
}

function validateOption(value, field, message, form) {
  if (value !== undefined) {
    return form;
  } else {
    return Helper$ReasonForm.addError(field, Utils_option.lazyDef(defaultMessage, message), form);
  }
}

function validateString(value, field, message, form) {
  if (value === "" || value.trim() === "") {
    return Helper$ReasonForm.addError(field, Utils_option.lazyDef(defaultMessage, message), form);
  } else {
    return form;
  }
}

function validateOptionString(value, field, message, form) {
  if (value !== undefined) {
    var v = value;
    if (v === "" || v.trim() === "") {
      return Helper$ReasonForm.addError(field, Utils_option.lazyDef(defaultMessage, message), form);
    } else {
      return form;
    }
  } else {
    return Helper$ReasonForm.addError(field, Utils_option.lazyDef(defaultMessage, message), form);
  }
}

var lazyDef = Utils_option.lazyDef;

export {
  lazyDef ,
  defaultMessage ,
  validateOption ,
  validateString ,
  validateOptionString ,
  
}
/* No side effect */
