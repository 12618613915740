// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ReactDOMRe from "reason-react/lib/es6/src/ReactDOMRe.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_option from "./Utils_option.bs.js";
import * as UseKey from "react-use/lib/useKey";
import * as ComponentSize from "@rehooks/component-size";
import * as UseKeyPress from "react-use/lib/useKeyPress";
import * as UseWindowSize from "react-use/lib/useWindowSize";

function ste(prim) {
  return prim;
}

var $$null = null;

function defste(opt, def) {
  if (opt !== undefined) {
    return opt;
  } else {
    return def;
  }
}

function ite(i) {
  return String(i);
}

function ate(prim) {
  return prim;
}

var lte = $$Array.of_list;

function mapOrNull(option, mapper) {
  return Belt_Option.mapWithDefault(option, null, mapper);
}

function combineProps(p1, p2) {
  if (p1 !== undefined) {
    var p1$1 = Caml_option.valFromOption(p1);
    if (p2 !== undefined) {
      return Object.assign(p1$1, Caml_option.valFromOption(p2));
    } else {
      return p1$1;
    }
  } else if (p2 !== undefined) {
    return Caml_option.valFromOption(p2);
  } else {
    return { };
  }
}

function strictCombineProps(p1, p2) {
  return combineProps(Caml_option.some(p1), Caml_option.some(p2));
}

function combineStyleInProps(style, props) {
  var match = props.style;
  var style$1 = (match == null) ? style : ReactDOMRe.Style.combine(style, match);
  return strictCombineProps(props, {
              style: style$1
            });
}

function preventCallback(callback, $$event) {
  $$event.preventDefault();
  return Curry._1(callback, /* () */0);
}

function getValue($$event) {
  var value = $$event.target.value;
  return Utils_option.def("", (value == null) ? undefined : Caml_option.some(value));
}

function isChecked($$event) {
  var value = $$event.target.checked;
  return Utils_option.def(false, (value == null) ? undefined : Caml_option.some(value));
}

function getNativeMouseEvent($$event) {
  return $$event.nativeEvent;
}

var newlineRegex = (/(\r\n|\r|\n)/g);

function nl2br(str) {
  return Belt_Array.mapWithIndexU(str.split(newlineRegex), (function (index, line) {
                if (line !== undefined) {
                  var line$1 = line;
                  var match = line$1.match(newlineRegex);
                  if (match !== null) {
                    return React.createElement("br", {
                                key: "br" + String(index)
                              });
                  } else {
                    return line$1;
                  }
                } else {
                  return null;
                }
              }));
}

function let_(children, render) {
  return Curry._1(children, render);
}

var Props = {
  let_: let_
};

function useHover(withRef, param) {
  var match = React.useState((function () {
          return false;
        }));
  var setValue = match[1];
  var ref = React.useRef(null);
  var ref$1 = withRef !== undefined ? Caml_option.valFromOption(withRef) : ref;
  var handleMouseOver = function (param) {
    return Curry._1(setValue, (function (param) {
                  return true;
                }));
  };
  var handleMouseOut = function (param) {
    return Curry._1(setValue, (function (param) {
                  return false;
                }));
  };
  React.useEffect((function () {
          var node = ref$1.current;
          if (node == null) {
            return ;
          } else {
            node.addEventListener("mouseover", handleMouseOver);
            node.addEventListener("mouseout", handleMouseOut);
            return (function (param) {
                      node.removeEventListener("mouseover", handleMouseOver);
                      node.removeEventListener("mouseover", handleMouseOut);
                      return /* () */0;
                    });
          }
        }), /* array */[ref$1.current]);
  return /* tuple */[
          ref$1,
          match[0]
        ];
}

function usePrevious(value) {
  var ref = React.useRef(undefined);
  React.useEffect((function () {
          ref.current = Caml_option.some(value);
          return ;
        }));
  return ref.current;
}

function usePreventCallback0(callback) {
  return React.useCallback((function (param) {
                return preventCallback(callback, param);
              }), ([]));
}

function usePreventCallback(callback) {
  return React.useCallback((function (param) {
                return preventCallback(callback, param);
              }), /* array */[callback]);
}

function usePreventCallback1(callback, a) {
  return React.useCallback((function (param) {
                return preventCallback(callback, param);
              }), /* tuple */[
              callback,
              a
            ]);
}

function usePreventCallback2(callback, param) {
  return React.useCallback((function (param) {
                return preventCallback(callback, param);
              }), /* tuple */[
              callback,
              param[0],
              param[1]
            ]);
}

function useToggleShow(value) {
  var match = React.useState((function () {
          return value;
        }));
  var setValue = match[1];
  var toggle = React.useCallback((function (param) {
          return Curry._1(setValue, (function (v) {
                        return !v;
                      }));
        }));
  var show = React.useCallback((function (param) {
          return Curry._1(setValue, (function (param) {
                        return true;
                      }));
        }));
  var hide = React.useCallback((function (param) {
          return Curry._1(setValue, (function (param) {
                        return false;
                      }));
        }));
  var toggleWithPrevent = usePreventCallback(toggle);
  var showWithPrevent = usePreventCallback(show);
  var hideWithPrevent = usePreventCallback(hide);
  return {
          value: match[0],
          toggle: toggle,
          toggleWithPrevent: toggleWithPrevent,
          show: show,
          showWithPrevent: showWithPrevent,
          hide: hide,
          hideWithPrevent: hideWithPrevent
        };
}

function useComponentSize(ref) {
  var jsSize = ComponentSize.default(ref);
  return React.useMemo((function () {
                return {
                        width: jsSize.width,
                        height: jsSize.height
                      };
              }), /* tuple */[
              jsSize.width,
              jsSize.height
            ]);
}

var Size = {
  useComponentSize: useComponentSize
};

var Keyboard = { };

function useKey(prim, prim$1) {
  UseKey.default(prim, prim$1);
  return /* () */0;
}

function useKeyPress(prim) {
  return UseKeyPress.default(prim);
}

var Debounce = { };

function useWindowSize(param) {
  var p = UseWindowSize.default();
  return /* tuple */[
          p.width,
          p.height
        ];
}

export {
  ste ,
  $$null ,
  defste ,
  ite ,
  ate ,
  lte ,
  mapOrNull ,
  combineProps ,
  strictCombineProps ,
  combineStyleInProps ,
  preventCallback ,
  getValue ,
  isChecked ,
  getNativeMouseEvent ,
  newlineRegex ,
  nl2br ,
  Props ,
  useHover ,
  usePrevious ,
  usePreventCallback0 ,
  usePreventCallback ,
  usePreventCallback1 ,
  usePreventCallback2 ,
  useToggleShow ,
  Size ,
  useComponentSize ,
  Keyboard ,
  useKey ,
  useKeyPress ,
  Debounce ,
  useWindowSize ,
  
}
/* null Not a pure module */
