jq = require 'jquery'
Plugin = require './Plugin'

class VideoPlugin extends Plugin
    getName: () -> "video"
    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({label: 'Vidéos', icon: 'fa fa-video-camera'}, [
            {
                icon: "fa fa-youtube"
                label: "Youtube"
                clickCallback: (bbCodeEdit) ->
                    id = prompt("Entrez l'identifiant de la vidéo")
                    bbCodeEdit.insertText("[youtube]#{id}[/youtube]") if id?
            }
            {
                icon: "fa fa-vimeo"
                label: "Vimeo"
                clickCallback: (bbCodeEdit) ->
                    id = prompt("Entrez l'identifiant de la vidéo")
                    bbCodeEdit.insertText("[vimeo]#{id}[/vimeo]") if id?
            }
        ]))

module.exports = VideoPlugin