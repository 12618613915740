import jq from "jquery";
import debounce from "lodash/debounce";
import OnReady from "../lib/OnReady";

const onReady = new OnReady();

jq().ready(() => {
    onReady.ready("jquery", jq(document));
    if (jq("#dashboard-page").size() === 1) {
        onReady.ready("jquery-dashboard-page", jq("#dashboard-page"));
    }
});

jq("body").on("shown.bs.tab", event => {
    onReady.ready("shown.bs.tab", jq(event.currentTarget));
});

jq("body").on("shown.bs.modal", event => {
    onReady.ready("shown.bs.modal", jq(event.currentTarget));
});

const readyResize = debounce(() => {
    onReady.ready("window-resize", jq(document));
}, 1000);

jq(window).resize(() => {
    readyResize();
});

export default onReady;
