import {ApolloClient} from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
import {toIdValue} from "apollo-utilities";
import {defaultDataIdFromObject} from "apollo-cache-inmemory";
import {graphQlUrl} from "../../../build/temp/js/options";

function createRedirectFromId(typename) {
    return (_root, args, _context) => toIdValue(defaultDataIdFromObject({__typename: typename, id: args.id}));
}

const client = new ApolloClient({
    link: new HttpLink({
        uri: graphQlUrl,
        credentials: "include",
    }),
    cache: new InMemoryCache({
        dataIdFromObject: defaultDataIdFromObject,
        cacheRedirects: {
            Query: {
            },
        },
    }),
});

export default client;
