// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Jquery from "jquery";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function version (jquery){{return jquery.fn.jquery;}};

function get(t, index) {
  return Caml_option.nullable_to_opt(t.get(index));
}

function isChecked(elt) {
  return elt.is(":checked");
}

function onReady(cb) {
  Jquery.default("document").ready(cb);
  return /* () */0;
}

var $$Event = { };

function setVal(element, value) {
  element.select2("val", value);
  return /* () */0;
}

function setData(element, data) {
  element.select2("data", data);
  return /* () */0;
}

var Select2 = {
  setVal: setVal,
  setData: setData
};

export {
  version ,
  get ,
  isChecked ,
  onReady ,
  $$Event ,
  Select2 ,
  
}
/* jquery Not a pure module */
