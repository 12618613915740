Plugin = require './Plugin'

class EmailPlugin extends Plugin
    getName: () -> "email"
    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createGroup([
            {
                label: "email"
                clickCallback: (bbCodeEdit) ->
                    doc = bbCodeEdit.editor.getSession().getDocument()
                    sel = bbCodeEdit.editor.getSelectionRange()

                    email = window.prompt("Entrez une adresse email", "addresse@email.com")

                    doc.insert({row: sel.end.row, column: sel.end.column}, "[email=\"#{email}\"]#{email}[/email]")
            }
        ]))

module.exports = EmailPlugin