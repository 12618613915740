map = require "lodash/map"
Plugin = require './Plugin'

class ListPlugin extends Plugin
    constructor: () ->
        super()
        @ulStyles = []

    getName: () -> "list"

    addUlStyle: (style) ->
        @ulStyles.push(style)
        @

    initToolbar: (bbCodeEdit) ->
        if @ulStyles.length == 0
            bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createButton({
                icon: "fa fa-list-ul"
                clickCallback: @onClick("list", null)
            }))
        else
            bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({icon: "fa fa-list-ul"}, map(@ulStyles, (style) => {
                label: style,
                clickCallback: if style == "default" then @onClick("list", null) else @onClick("list", style)
            })))

        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createButton({
            icon: "fa fa-list-ol"
            clickCallback: @onClick("olist", null)
        }))

    onClick: (tag, style = null) => (bbCodeEdit) =>
        doc = bbCodeEdit.editor.getSession().getDocument()
        sel = bbCodeEdit.editor.getSelectionRange()
        if style?
            doc.insert({row: sel.end.row, column: sel.end.column}, "[#{tag} style=\"#{style}\"]\n[*][/*]\n[/#{tag}]")
        else
            doc.insert({row: sel.end.row, column: sel.end.column}, "[#{tag}]\n[*][/*]\n[/#{tag}]")

module.exports = ListPlugin