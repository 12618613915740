// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Modal from "react-bootstrap/lib/Modal";

function backdrop_to_js(backdrop) {
  if (backdrop !== -545181112) {
    if (backdrop >= 100392110) {
      return "static";
    } else {
      return true;
    }
  } else {
    return false;
  }
}

var ModalJs = { };

function React_bootstrap$Modal(Props) {
  var match = Props.animation;
  var animation = match !== undefined ? match : true;
  var match$1 = Props.bsClass;
  var bsClass = match$1 !== undefined ? match$1 : "modal";
  var match$2 = Props.bsSize;
  var bsSize = match$2 !== undefined ? match$2 : "lg";
  var match$3 = Props.className;
  var className = match$3 !== undefined ? match$3 : "";
  var match$4 = Props.autoFocus;
  var autoFocus = match$4 !== undefined ? match$4 : true;
  var match$5 = Props.backdrop;
  var backdrop = match$5 !== undefined ? match$5 : /* with_ */-898707559;
  var backdropClassName = Props.backdropClassName;
  var match$6 = Props.backdropStyle;
  var backdropStyle = match$6 !== undefined ? Caml_option.valFromOption(match$6) : { };
  var dialogClassName = Props.dialogClassName;
  var match$7 = Props.keyboard;
  var keyboard = match$7 !== undefined ? match$7 : true;
  var match$8 = Props.enforceFocus;
  var enforceFocus = match$8 !== undefined ? match$8 : true;
  var onBackdropClick = Props.onBackdropClick;
  var onEnter = Props.onEnter;
  var onEntered = Props.onEntered;
  var onEntering = Props.onEntering;
  var onEscapeKeyDown = Props.onEscapeKeyDown;
  var onExit = Props.onExit;
  var onExited = Props.onExited;
  var onExiting = Props.onExiting;
  var onHide = Props.onHide;
  var onShow = Props.onShow;
  var restoreFocus = Props.restoreFocus;
  var match$9 = Props.show;
  var show = match$9 !== undefined ? match$9 : true;
  var children = Props.children;
  var tmp = {
    animation: animation,
    bsClass: bsClass,
    bsSize: bsSize,
    className: className,
    autoFocus: autoFocus,
    backdrop: backdrop_to_js(backdrop),
    backdropClassName: backdropClassName,
    backdropStyle: backdropStyle,
    dialogClassName: dialogClassName,
    keyboard: keyboard,
    enforceFocus: enforceFocus,
    restoreFocus: restoreFocus,
    show: show,
    children: children
  };
  if (onBackdropClick !== undefined) {
    tmp.onBackdropClick = Caml_option.valFromOption(onBackdropClick);
  }
  if (onEnter !== undefined) {
    tmp.onEnter = Caml_option.valFromOption(onEnter);
  }
  if (onEntered !== undefined) {
    tmp.onEntered = Caml_option.valFromOption(onEntered);
  }
  if (onEntering !== undefined) {
    tmp.onEntering = Caml_option.valFromOption(onEntering);
  }
  if (onEscapeKeyDown !== undefined) {
    tmp.onEscapeKeyDown = Caml_option.valFromOption(onEscapeKeyDown);
  }
  if (onExit !== undefined) {
    tmp.onExit = Caml_option.valFromOption(onExit);
  }
  if (onExited !== undefined) {
    tmp.onExited = Caml_option.valFromOption(onExited);
  }
  if (onExiting !== undefined) {
    tmp.onExiting = Caml_option.valFromOption(onExiting);
  }
  if (onHide !== undefined) {
    tmp.onHide = Caml_option.valFromOption(onHide);
  }
  if (onShow !== undefined) {
    tmp.onShow = Caml_option.valFromOption(onShow);
  }
  return React.createElement(Modal.default, tmp);
}

var Body = { };

var Header = { };

var Title = { };

var Footer = { };

var Modal$1 = {
  backdrop_to_js: backdrop_to_js,
  ModalJs: ModalJs,
  make: React_bootstrap$Modal,
  Body: Body,
  Header: Header,
  Title: Title,
  Footer: Footer
};

export {
  Modal$1 as Modal,
  
}
/* react Not a pure module */
