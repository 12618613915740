ace.define "netosoftutils/mode/bbcode_highlight_rules",
    ["require", "exports", "module", "ace/lib/oop", "ace/mode/text_highlight_rules"],
    (acequire, exports, module) ->
        oop = acequire "ace/lib/oop"
        {TextHighlightRules} = acequire "ace/mode/text_highlight_rules"

        BbCodeHighlightRules = (normalize) ->
            tagRegex = "[a-zA-Z][-_a-zA-Z0-9]*";

            this.$rules = {
                start: [
                    {include: "tag"}
                    {defaultToken : "text.xml"}
                ],

                tag: [{
                    token: ["meta.tag.punctuation.tag-open.xml", "meta.tag.punctuation.end-tag-open.xml",
                            "meta.tag.tag-name.xml"],
                    regex: "(?:(\\[)|(\\[/))((?:" + tagRegex + ":)?" + tagRegex + ")",
                    next: [
                        {include: "attributes"},
                        {token: "meta.tag.punctuation.tag-close.xml", regex: "/?\\]", next: "start"}
                    ]
                }]

                tag_whitespace: [
                    {token: "text.tag-whitespace.xml", regex: "\\s+"}
                ]

                attributes: [{
                    token: "entity.other.attribute-name.xml"
                    regex: "(?:" + tagRegex + ":)?" + tagRegex + ""
                }, {
                    token: "keyword.operator.attribute-equals.xml"
                    regex: "="
                }, {
                    include: "tag_whitespace"
                }, {
                    include: "attribute_value"
                }]

                attr_reference : [{
                    token : "constant.language.escape.reference.attribute-value.xml"
                    regex : "(?:&#[0-9]+;)|(?:&#x[0-9a-fA-F]+;)|(?:&[a-zA-Z0-9_:\\.-]+;)"
                }]

                attribute_value: [{
                    token: "string.attribute-value.xml"
                    regex: "'"
                    push: [
                        {token: "string.attribute-value.xml", regex: "'", next: "pop"}
                        {include: "attr_reference"}
                        {defaultToken: "string.attribute-value.xml"}
                    ]
                }, {
                    token: "string.attribute-value.xml"
                    regex: '"'
                    push: [
                        {token: "string.attribute-value.xml", regex: '"', next: "pop"}
                        {include: "attr_reference"}
                        {defaultToken: "string.attribute-value.xml"}
                    ]
                }]
            }

            if this.constructor == BbCodeHighlightRules
                this.normalizeRules()

        oop.inherits(BbCodeHighlightRules, TextHighlightRules);
        exports = {BbCodeHighlightRules}

ace.define "netosoftutils/mode/bbcode",
    ["require", "exports", "module", "ace/lib/oop", "ace/lib/lang", "ace/mode/text",
     "netosoftutils/mode/bbcode_highlight_rules", "ace/worker/worker_client"],
    (acequire, exports, module) ->
        oop = acequire("ace/lib/oop")
        lang = acequire("ace/lib/lang")
        TextMode = acequire("ace/mode/text").Mode
        {BbCodeHighlightRules} = acequire("./bbcode_highlight_rules")
        WorkerClient = acequire("ace/worker/worker_client").WorkerClient

        Mode = () ->
            this.HighlightRules = BbCodeHighlightRules
            return

        oop.inherits(Mode, TextMode)

        func = () ->
            this.voidElements = lang.arrayToMap([])

            this.blockComment = {start: "<!--", end: "-->"}

            this.createWorker = (session) -> null

            this.$id = "netosoftutils/mode/xml"
        func.call(Mode.prototype)

        exports = {Mode};
