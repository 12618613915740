// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "bs-platform/lib/es6/caml_obj.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils_react from "../utils/Utils_react.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/lib/es6/src/form.bs.js";
import * as Hook$ReasonForm from "@maarekj/reason-form/lib/es6/src/Hook.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/lib/es6/src/wrap.bs.js";
import * as Helper$ReasonForm from "@maarekj/reason-form/lib/es6/src/helper.bs.js";
import * as Caml_chrome_debugger from "bs-platform/lib/es6/caml_chrome_debugger.js";

function normalizeId(id) {
  var reg = (/[^-_a-zA-Z0-9]/g);
  return id.replace(reg, "-");
}

function Component_form_render$FieldErrors(Props) {
  var wrap = Props.wrap;
  var field = Props.field;
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match.nbSubmits;
  var match$1 = Hook$ReasonForm.useMeta(wrap, field);
  var errors = match$1.errors;
  var isDirty = match$1.isDirty;
  return React.useMemo((function () {
                var match = (nbSubmits > 0 || isDirty) && Belt_List.length(errors) !== 0;
                if (match) {
                  return React.createElement("div", {
                              className: "help-block",
                              style: {
                                display: "block"
                              }
                            }, Belt_List.toArray(Belt_List.map(errors, (function (prim) {
                                        return prim;
                                      }))));
                } else {
                  return null;
                }
              }), /* tuple */[
              nbSubmits,
              isDirty,
              errors
            ]);
}

var FieldErrors = {
  make: Component_form_render$FieldErrors
};

function Component_form_render$FormErrors(Props) {
  var wrap = Props.wrap;
  var match = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match.nbSubmits;
  var submitErrors = match.submitErrors;
  var hasSubmitErrors = match.hasSubmitErrors;
  var rootErrors = match.rootErrors;
  var hasRootErrors = match.hasRootErrors;
  return React.useMemo((function () {
                var match = nbSubmits > 0 && (hasRootErrors || hasSubmitErrors);
                if (match) {
                  return React.createElement("div", {
                              className: "help-block",
                              style: {
                                display: "block"
                              }
                            }, Belt_List.toArray(Belt_List.map(rootErrors, (function (prim) {
                                        return prim;
                                      }))), Belt_List.toArray(Belt_List.map(submitErrors, (function (prim) {
                                        return prim;
                                      }))));
                } else {
                  return null;
                }
              }), /* tuple */[
              nbSubmits,
              hasRootErrors,
              hasSubmitErrors,
              rootErrors,
              submitErrors
            ]);
}

var FormErrors = {
  make: Component_form_render$FormErrors
};

function Component_form_render$Input(Props) {
  var wrap = Props.wrap;
  var match = Props.type_;
  var type_ = match !== undefined ? match : "text";
  var id = Props.id;
  var match$1 = Props.className;
  var className = match$1 !== undefined ? match$1 : "form-control";
  var toText = Props.toText;
  var fromText = Props.fromText;
  var field = Props.field;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var fieldField = React.useMemo((function () {
          return /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
        }), /* array */[field]);
  var match$2 = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match$2.nbSubmits;
  var match$3 = Hook$ReasonForm.useMeta(wrap, fieldField);
  var hasError = match$3.hasError;
  var isDirty = match$3.isDirty;
  var key = match$3.key;
  return React.useMemo((function () {
                console.log("render input");
                var partial_arg = /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
                var focus = function (param) {
                  return Helper$ReasonForm.focus(partial_arg, param);
                };
                var partial_arg$1 = /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
                var blur = function (param) {
                  return Helper$ReasonForm.blur(partial_arg$1, param);
                };
                var id$1;
                if (id !== undefined) {
                  id$1 = id;
                } else {
                  var prefixId = Wrap$ReasonForm.id(wrap);
                  id$1 = normalizeId(prefixId + ("-" + key));
                }
                var match = (nbSubmits > 0 || isDirty) && hasError;
                return React.createElement("input", {
                            className: className + (" " + (
                                match ? "is-invalid" : ""
                              )),
                            id: id$1,
                            type: type_,
                            value: Curry._1(toText, value),
                            onFocus: (function (_event) {
                                return Wrap$ReasonForm.dispatch(wrap, focus);
                              }),
                            onBlur: (function (_event) {
                                return Wrap$ReasonForm.dispatch(wrap, blur);
                              }),
                            onChange: (function ($$event) {
                                var value = Curry._1(fromText, Helper$ReasonForm.getValue($$event));
                                return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                              var value$1 = value;
                                              var form = param;
                                              var value$2 = Curry._2(field.setValue, value$1, Form$ReasonForm.getValues(form));
                                              return Form$ReasonForm.changeValues(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                            field.key,
                                                            /* [] */0
                                                          ]), value$2, form);
                                            }));
                              })
                          });
              }), /* tuple */[
              value,
              nbSubmits,
              isDirty,
              hasError,
              key,
              className,
              toText,
              fromText,
              id
            ]);
}

var Input = {
  make: Component_form_render$Input
};

function Component_form_render$BoolCheckbox(Props) {
  var wrap = Props.wrap;
  var id = Props.id;
  var label = Props.label;
  var match = Props.className;
  var className = match !== undefined ? match : "checkbox";
  var field = Props.field;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var fieldField = React.useMemo((function () {
          return /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
        }), /* array */[field]);
  var match$1 = Hook$ReasonForm.useMeta(wrap, fieldField);
  var key = match$1.key;
  return React.useMemo((function () {
                console.log("render checkbox");
                var partial_arg = /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
                var focus = function (param) {
                  return Helper$ReasonForm.focus(partial_arg, param);
                };
                var partial_arg$1 = /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
                var blur = function (param) {
                  return Helper$ReasonForm.blur(partial_arg$1, param);
                };
                var id$1;
                if (id !== undefined) {
                  id$1 = id;
                } else {
                  var prefixId = Wrap$ReasonForm.id(wrap);
                  id$1 = normalizeId(prefixId + ("-" + key));
                }
                return React.createElement("div", {
                            className: className
                          }, React.createElement("label", undefined, React.createElement("input", {
                                    id: id$1,
                                    checked: value === true,
                                    type: "checkbox",
                                    onFocus: (function (_event) {
                                        return Wrap$ReasonForm.dispatch(wrap, focus);
                                      }),
                                    onBlur: (function (_event) {
                                        return Wrap$ReasonForm.dispatch(wrap, blur);
                                      }),
                                    onChange: (function ($$event) {
                                        var partial_arg = Utils_react.isChecked($$event);
                                        return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                                      var value = partial_arg;
                                                      var form = param;
                                                      var value$1 = Curry._2(field.setValue, value, Form$ReasonForm.getValues(form));
                                                      return Form$ReasonForm.changeValues(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                                    field.key,
                                                                    /* [] */0
                                                                  ]), value$1, form);
                                                    }));
                                      })
                                  }), React.createElement("span", undefined, label)));
              }), /* tuple */[
              value,
              key,
              className,
              id
            ]);
}

var BoolCheckbox = {
  make: Component_form_render$BoolCheckbox
};

function Component_form_render$Choice(Props) {
  var wrap = Props.wrap;
  var match = Props.expanded;
  var expanded = match !== undefined ? match : false;
  var match$1 = Props.className;
  var className = match$1 !== undefined ? match$1 : "form-control";
  var id = Props.id;
  var choices = Props.choices;
  var field = Props.field;
  var value = Hook$ReasonForm.useValue(wrap, field);
  var fieldField = React.useMemo((function () {
          return /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
        }), /* array */[field]);
  var match$2 = Hook$ReasonForm.useFormMeta(wrap);
  var nbSubmits = match$2.nbSubmits;
  var match$3 = Hook$ReasonForm.useMeta(wrap, fieldField);
  var hasError = match$3.hasError;
  var isDirty = match$3.isDirty;
  var key = match$3.key;
  return React.useMemo((function () {
                var partial_arg = /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
                var focus = function (param) {
                  return Helper$ReasonForm.focus(partial_arg, param);
                };
                var partial_arg$1 = /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
                var blur = function (param) {
                  return Helper$ReasonForm.blur(partial_arg$1, param);
                };
                var setValue = function (value, form) {
                  var value$1 = Curry._2(field.setValue, value, Form$ReasonForm.getValues(form));
                  return Form$ReasonForm.changeValues(/* :: */Caml_chrome_debugger.simpleVariant("::", [
                                field.key,
                                /* [] */0
                              ]), value$1, form);
                };
                var id$1;
                if (id !== undefined) {
                  id$1 = id;
                } else {
                  var prefixId = Wrap$ReasonForm.id(wrap);
                  id$1 = normalizeId(prefixId + ("-" + key));
                }
                var toString = function (value) {
                  var choice = Belt_List.getByU(choices, (function (choice) {
                          return Caml_obj.caml_equal(choice.value, value);
                        }));
                  if (choice !== undefined) {
                    return choice.string;
                  } else {
                    return "";
                  }
                };
                var fromString = function (string) {
                  var choice = Belt_List.getByU(choices, (function (choice) {
                          return choice.string === string;
                        }));
                  if (choice !== undefined) {
                    return Caml_option.some(choice.value);
                  }
                  
                };
                var string = toString(value);
                console.log("render choice");
                if (expanded) {
                  var match = (nbSubmits > 0 || isDirty) && hasError;
                  return React.createElement("div", {
                              className: match ? "is-invalid" : ""
                            }, Belt_List.toArray(Belt_List.mapU(choices, (function (choice) {
                                        var partial_arg = choice.value;
                                        return React.createElement("div", {
                                                    key: choice.string,
                                                    className: "form-check"
                                                  }, React.createElement("input", {
                                                        className: "form-check-input",
                                                        id: normalizeId(id$1 + ("--" + choice.string)),
                                                        checked: string === choice.string,
                                                        type: "radio",
                                                        value: choice.string,
                                                        onChange: (function (param) {
                                                            var value = partial_arg;
                                                            return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                                                          return setValue(value, param);
                                                                        }));
                                                          })
                                                      }), React.createElement("label", {
                                                        className: "form-check-label",
                                                        htmlFor: normalizeId(id$1 + ("--" + choice.string))
                                                      }, choice.label));
                                      }))));
                } else {
                  var match$1 = (nbSubmits > 0 || isDirty) && hasError;
                  return React.createElement("select", {
                              className: className + (" " + (
                                  match$1 ? "is-invalid" : ""
                                )),
                              id: id$1,
                              value: string,
                              onFocus: (function (_event) {
                                  return Wrap$ReasonForm.dispatch(wrap, focus);
                                }),
                              onBlur: (function (_event) {
                                  return Wrap$ReasonForm.dispatch(wrap, blur);
                                }),
                              onChange: (function ($$event) {
                                  var value = fromString(Helper$ReasonForm.getValue($$event));
                                  if (value !== undefined) {
                                    var value$1 = Caml_option.valFromOption(value);
                                    return Wrap$ReasonForm.dispatch(wrap, (function (param) {
                                                  return setValue(value$1, param);
                                                }));
                                  } else {
                                    return /* () */0;
                                  }
                                })
                            }, Belt_List.toArray(Belt_List.mapU(choices, (function (choice) {
                                        return React.createElement("option", {
                                                    key: choice.string,
                                                    value: choice.string
                                                  }, choice.label);
                                      }))));
                }
              }), /* tuple */[
              value,
              nbSubmits,
              isDirty,
              hasError,
              key,
              className,
              choices,
              expanded,
              id
            ]);
}

var Choice = {
  make: Component_form_render$Choice
};

function Component_form_render$Row(Props) {
  var label = Props.label;
  var labelFor = Props.labelFor;
  var match = Props.className;
  var className = match !== undefined ? match : "form-group";
  var input = Props.input;
  var wrap = Props.wrap;
  var field = Props.field;
  var fieldField = React.useMemo((function () {
          return /* `field */Caml_chrome_debugger.polyVar("field", [
                    9671866,
                    field
                  ]);
        }), /* array */[field]);
  var match$1 = Hook$ReasonForm.useFormMeta(wrap);
  var match$2 = Hook$ReasonForm.useMeta(wrap, fieldField);
  var labelFor$1;
  if (labelFor !== undefined) {
    labelFor$1 = labelFor;
  } else {
    var prefixId = Wrap$ReasonForm.id(wrap);
    labelFor$1 = normalizeId(prefixId + ("-" + match$2.key));
  }
  var match$3 = (match$1.nbSubmits > 0 || match$2.isDirty) && match$2.hasError;
  return React.createElement("div", {
              className: className + (" " + (
                  match$3 ? "has-error" : ""
                ))
            }, label !== undefined ? React.createElement("label", {
                    htmlFor: labelFor$1
                  }, label) : null, input, React.createElement(Component_form_render$FieldErrors, {
                  wrap: wrap,
                  field: fieldField
                }));
}

var Row = {
  make: Component_form_render$Row
};

function Component_form_render$ObjectRow(Props) {
  var label = Props.label;
  var match = Props.className;
  var className = match !== undefined ? match : "form-group";
  var input = Props.input;
  var wrap = Props.wrap;
  var field = Props.field;
  var objField = React.useMemo((function () {
          return /* `obj */Caml_chrome_debugger.polyVar("obj", [
                    5541879,
                    field
                  ]);
        }), /* array */[field]);
  return React.createElement("div", {
              className: className
            }, React.createElement("label", undefined, label), input, React.createElement(Component_form_render$FieldErrors, {
                  wrap: wrap,
                  field: objField
                }));
}

var ObjectRow = {
  make: Component_form_render$ObjectRow
};

function Component_form_render$List(Props) {
  var wrap = Props.wrap;
  var onAdd = Props.onAdd;
  var onRemove = Props.onRemove;
  var label = Props.label;
  var renderInput = Props.renderInput;
  var field = Props.field;
  var listField = React.useMemo((function () {
          return /* `list */Caml_chrome_debugger.polyVar("list", [
                    -944563106,
                    field
                  ]);
        }), /* array */[field]);
  var count = Hook$ReasonForm.useListCount(wrap, field);
  var rows = React.useMemo((function () {
          return Belt_List.makeByU(count, (function (i) {
                        return Curry._1(field.getRow, i);
                      }));
        }), /* tuple */[
        count,
        field
      ]);
  return React.useMemo((function () {
                return React.createElement("div", {
                            className: "form-group"
                          }, React.createElement("label", undefined, label), React.createElement("ul", undefined, Belt_List.toArray(Belt_List.mapWithIndex(rows, (function (i, row) {
                                          return React.createElement("li", {
                                                      key: String(i)
                                                    }, Curry._2(renderInput, row, i), React.createElement("button", {
                                                          className: "btn btn-light",
                                                          onClick: (function ($$event) {
                                                              $$event.preventDefault();
                                                              return Curry._1(onRemove, i);
                                                            })
                                                        }, "❌"));
                                        })))), React.createElement(Component_form_render$FieldErrors, {
                                wrap: wrap,
                                field: listField
                              }), React.createElement("button", {
                                className: "btn btn-secondary",
                                onClick: (function ($$event) {
                                    $$event.preventDefault();
                                    return Curry._1(onAdd, /* () */0);
                                  })
                              }, "Add +"));
              }), /* tuple */[
              listField,
              rows,
              onAdd,
              onRemove,
              label,
              renderInput
            ]);
}

var List = {
  make: Component_form_render$List
};

function Component_form_render$SubmitButton(Props) {
  var wrap = Props.wrap;
  var text = Props.text;
  var submittingText = Props.submittingText;
  var formMeta = Hook$ReasonForm.useFormMeta(wrap);
  var isSubmitting = formMeta.isSubmitting;
  var match = formMeta.hasErrors || isSubmitting;
  return React.createElement("button", {
              className: "btn btn-primary " + (
                match ? "disabled" : ""
              ),
              type: "submit"
            }, isSubmitting ? submittingText : text);
}

var SubmitButton = {
  make: Component_form_render$SubmitButton
};

function Component_form_render$ResetButton(Props) {
  var wrap = Props.wrap;
  var initialForm = Props.initialForm;
  var text = Props.text;
  var formMeta = Hook$ReasonForm.useFormMeta(wrap);
  return React.createElement("button", {
              className: "btn btn-light " + (
                formMeta.isSubmitting ? "disabled" : ""
              ),
              onClick: (function ($$event) {
                  $$event.preventDefault();
                  return Wrap$ReasonForm.dispatch(wrap, (function (_form) {
                                return initialForm;
                              }));
                })
            }, text);
}

var ResetButton = {
  make: Component_form_render$ResetButton
};

function Component_form_render$Form(Props) {
  var wrap = Props.wrap;
  var className = Props.className;
  var onSubmit = Props.onSubmit;
  var render = Props.render;
  var tmp = {
    onSubmit: (function ($$event) {
        $$event.preventDefault();
        Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.startSubmit);
        Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.clearSubmitErrors);
        return Wrap$ReasonForm.dispatch(wrap, (function (form) {
                      if (Form$ReasonForm.formHasErrors(form)) {
                        return Form$ReasonForm.stopSubmit(form);
                      } else {
                        Curry._1(onSubmit, form).then((function (value) {
                                if (value.tag) {
                                  var error = value[0];
                                  var f = function (param) {
                                    return Form$ReasonForm.addSubmitError(error, param);
                                  };
                                  Wrap$ReasonForm.dispatch(wrap, f);
                                  Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.stopSubmit);
                                  return Promise.resolve(/* () */0);
                                } else {
                                  Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.submitSuccess);
                                  Wrap$ReasonForm.dispatch(wrap, Form$ReasonForm.stopSubmit);
                                  return Promise.resolve(/* () */0);
                                }
                              }));
                        return form;
                      }
                    }));
      })
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("form", tmp, render);
}

var Form = {
  make: Component_form_render$Form
};

export {
  normalizeId ,
  FieldErrors ,
  FormErrors ,
  Input ,
  BoolCheckbox ,
  Choice ,
  Row ,
  ObjectRow ,
  List ,
  SubmitButton ,
  ResetButton ,
  Form ,
  
}
/* react Not a pure module */
