import jq from "jquery";
import _ from "lodash";
import onReady from "./src/onReady";
import CollectionType from "./src/collectionType";
import {SonataAdmin, main as sonataAdminMain} from "./SonataAdmin";

import numeral from "numeral";
import xModalForm from "netosoftdomain/js/xModalForm";
import renderFieldUrl from "netosoftdomain/js/renderFieldUrl";
import SelectorHelper from "./lib/SelectorHelper";
import {initializeFromConfig} from "./lib/reactUtils";
import BbCode from "./BbCode/index";


sonataAdminMain();

numeral.register("locale", "fr", {
    delimiters: {
        thousands: " ",
        decimal: ",",
    },
    abbreviations: {
        thousand: "k",
        million: "m",
        billion: "b",
        trillion: "t",
    },
    ordinal: function (number) {
        return number === 1 ? "er" : "ème";
    },
    currency: {
        symbol: "€",
    },
});
numeral.locale("fr");

//
// Bootstrap Toggle
//
onReady.createAnonym(["jquery", "content-loaded", "x-modal-form"], $parent => {
    $parent.find("[data-toggle=tooltip]").tooltip();
    $parent.find("[data-toggle=popover]").popover();
});

onReady.createAnonym(["x-modal-form", "content-loaded"], $dom => {
    SonataAdmin.setup_select2($dom);
    SonataAdmin.setup_icheck($dom);
    SonataAdmin.setup_xeditable($dom);
    SonataAdmin.setup_collection_counter($dom);
});

onReady.createAnonym(["jquery", "x-modal-form"], $dom => {
    $dom.find("[data-toggle=x-modal-form]").each((i, element) => {
        const $target = jq(element);
        xModalForm($target, {
            onModalLoaded: $modal => {
                onReady.ready("content-loaded", $modal);
            },
            onSuccess: ({$target}) => {
                const $element = SelectorHelper.get().select($target.data("reload-selector"), {default: $target});
                const url = $target.data("reload-url");
                renderFieldUrl({
                    $element,
                    url,
                    onReady: $element => {
                        onReady.ready("x-modal-form", $element);
                    },
                });
            },
        });
    });
});

onReady.createAnonym(["jquery"], $dom => {
    $dom.on('app-collection-item-added', (event) => {
        onReady.ready("collection-item-added", jq(event.target));
    });
});

onReady.createAnonym(["jquery", "collection-item-added", "x-modal-form", "content-loaded"], $dom => {
    $dom.find('[data-toggle="app-collection-type"]').appCollectionType();
});

onReady.createAnonym(["jquery", "content-loaded"], $dom => {
});

onReady.createAnonym(["jquery"], $dom => {
    $dom.find("[data-toggle=toggle-filters]").on("click", event => {
        event.preventDefault();
        $dom.find(".sonata-filters").toggle();
    });

    if (window.innerWidth <= 1024) {
        $dom.find(".sonata-filters").hide();
    }
});

onReady.createAnonym(["jquery"], $dom => {
});

onReady.createAnonym(["jquery"], $dom => {
    $dom.on("click", "[data-form-target=_blank]", event => {
        const $button = jq(event.currentTarget);
        const $form = $button.closest("form");
        $form.attr("target", "_blank");

        setTimeout(() => {
            $form.attr("target", "");
        }, 0);
    });
});

onReady.createAnonym(["jquery"], $dom => {
    BbCode.BbCodeEdit.addPlugin(BbCode.plugins.ImagePlugin());

    const plugins = ["table", "text", "title", "list", "email", "url", "nbsp"];
    const fullPlugins = [...plugins, "image", "video", "post"];

    BbCode.BbCodeEdit.authorizePluginsForType(fullPlugins, "default");

    BbCode.BbCodeEdit.getPlugin("post")
        .addFramedStyle("light-gray")
        .addFramedStyle("dark-gray")
        .addFramedStyle("yellow")
        .addFramedStyle("blue")
        .addFramedStyle("green")
        .addFramedStyle("red")
        .addFramedStyle("orange");

    BbCode.BbCodeEdit.getPlugin("list")
        .addUlStyle("default")
        .addUlStyle("disk")
        .addUlStyle("circle")
        .addUlStyle("square");
});

onReady.createAnonym(["jquery", "collection-item-added"], $dom => {
    $dom.find('[data-toggle="bbCodeEdit"]').bbCodeEdit();
});
