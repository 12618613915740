lodash = require 'lodash'

class OnReady
    constructor: () ->
        @names = {}
        @callbacks = {
            jquery: []
        }


    createAnonym: (groups, cb) ->
        if lodash.isString(groups)
            groups = [groups]

        name = lodash.uniqueId('onready_anonym_')

        return @create(name, groups, cb)


    create: (name, args...) ->
        if args.length == 1
            groups = []
            cb = args[0]
        else
            groups = args[0]
            if lodash.isString(groups)
                groups = [groups]

            cb = args[1]

        @names[name] = cb

        for group in groups
            @push(group, name)

        return @

    push: (group, name) ->
        cbs = @callbacks[group]
        cbs = [] if !cbs?
        cbs.push(@get(name))
        @callbacks[group] = cbs

    get: (name) ->
        @names[name]

    ready: (group, rest...) ->
        lodash.forEach(@callbacks[group], (cb) ->
            cb(rest...)
        )

module.exports = OnReady