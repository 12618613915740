jq = require 'jquery'

JqueryUtils = require('../lib/JqueryUtils').default
ace = require 'brace'
require './BbCodeMode'
require 'brace/theme/chrome'
find = require 'lodash/find'
filter = require 'lodash/filter'
escape = require 'lodash/escape'
includes = require 'lodash/includes'

ToolbarBuilder = require './ToolbarBuilder'
TextPlugin = require './Plugin/TextPlugin'
TitlePlugin = require './Plugin/TitlePlugin'
ListPlugin = require './Plugin/ListPlugin'
EmailPlugin = require './Plugin/EmailPlugin'
UrlPlugin = require './Plugin/UrlPlugin'
PostPlugin = require './Plugin/PostPlugin'
TablePlugin = require './Plugin/TablePlugin'
VideoPlugin = require './Plugin/VideoPlugin'

namePlugin = 'bbCodeEdit'
prefix = 'bbcode-edit'

class BbCodeEdit
    @plugins = []
    @types = {}

    @addPlugins = (plugins) ->
        for plugin in plugins
            BbCodeEdit.addPlugin(plugin)

    @addPlugin = (plugin) ->
        BbCodeEdit.plugins.push(plugin)

    @getPlugin = (name) ->
        find(@plugins, (plugin) -> plugin.getName() == name)

    @authorizePluginForType = (pluginName, type) ->
        BbCodeEdit.types[type] ?= []
        BbCodeEdit.types[type].push(pluginName)

    @authorizePluginsForType = (pluginNames, type) ->
        for pluginName in pluginNames
            BbCodeEdit.authorizePluginForType(pluginName, type)

    @getPluginsForType = (type) ->
        filter BbCodeEdit.plugins, (plugin) ->
            includes(BbCodeEdit.types[type], plugin.getName())

    constructor: (@$container, options) ->
        {
        editorId
        @$input
        @$toolbar
        @$preview
        @urlPreview
        @bbcodeType
        @extra
        @rendererOptions
        } = options

        @editor = ace.edit(editorId)
        @toolbarBuilder = new ToolbarBuilder(@)
        @loading = 0
        @timerOnChange = null

    @fromDom = (opts) ->
        {$container} = opts

        widget = new BbCodeEdit $container, {
            editorId: $container.data('editorId')
            $input: $container.find($container.data('inputSelector'))
            $toolbar: $container.find($container.data('toolbarSelector'))
            $preview: $container.find($container.data('previewSelector'))
            urlPreview: $container.data('urlPreview')
            bbcodeType: $container.data('bbcodeType')
            extra: $container.data('extra')
            rendererOptions: $container.data('rendererOptions')
        }
        widget.init()

        return widget;

    init: () ->
        JqueryUtils.preInit(@$container, namePlugin, @)

        window.AA = @editor
        @editor.setTheme('ace/theme/chrome')
        @editor.getSession().setMode('netosoftutils/mode/bbcode');
        @editor.getSession().setUseWrapMode(true)
        @editor.setValue(@$input.val())
        @editor.clearSelection()
        @editor.moveCursorToPosition({row: 0, column: 0})

        if @rendererOptions?
            @editor.renderer.setOptions(@rendererOptions)


        @initToolbar()
        @editor.getSession().on 'change', () => @onChangeDelay()
        @renderPreview()

    initToolbar: () ->
        for plugin in BbCodeEdit.getPluginsForType(@bbcodeType)
            plugin.initToolbar(@)

    destroy: () ->
        @$toolbar.html('')
        JqueryUtils.postDestroy(@$container, namePlugin, @)

    onChangeDelay: () ->
        @$input.val(@editor.getValue())
        @$input.trigger 'change'

        clearTimeout(@timerOnChange) if @timerOnChange?
        @timerOnChange = setTimeout(() =>
            @renderPreview()
        , 2000)

    renderPreview: () ->
        data = {
            text: @editor.getValue()
            type: @bbcodeType
        }

        @loading++
        @$preview.addClass('loading') if @loading >= 1

        @previewXhr.abort() if @previewXhr?.abort?
        @previewXhr = jQuery.ajax(
            url: @urlPreview,
            type: 'POST',
            data: data
        )
        .done (html) =>
            @loading--
            @$preview.removeClass('loading') if @loading == 0
            $html = jq("<div>#{html}</div>")
            @$preview.html('')
            @$preview.append($html)

            $html.trigger("#{prefix}-preview")

        .fail (jqXHR, textStatus) =>
            @loading--
            @$preview.removeClass('loading') if @loading == 0
            if textStatus != 'abort'
                console.log("Request failed: " + textStatus);
                $html = jq("<div>Request failed: #{textStatus}</div>")
                @$preview.html("")
                @preview.append($html)

                $html.trigger("#{prefix}-preview-failed")


    constructAttrsText: (attrs) ->
        attrs ?= {}

        result = ""
        if attrs._default?
            result += '="' + attrs._default + '"'

        for key, value of attrs
            if key != '_default'
                result += " " + key + '="' + escape(value) + '"'

        return result


    insertTagAutoclosed: (tag, attrs) ->
        doc = @editor.getSession().getDocument()
        sel = @editor.getSelectionRange()

        tag = "[" + tag + @constructAttrsText(attrs) + "/]"
        doc.insert({row: sel.end.row, column: sel.end.column}, tag)

    wrapSelectionWithTag: (tag, attrs) ->
        doc = @editor.getSession().getDocument()
        sel = @editor.getSelectionRange()

        startTag = "[" + tag + @constructAttrsText(attrs) + "]"
        doc.insert({row: sel.start.row, column: sel.start.column}, startTag)

        sel = @editor.getSelectionRange()
        doc.insert({row: sel.end.row, column: sel.end.column}, "[/#{tag}]")

    insertText: (text) ->
        doc = @editor.getSession().getDocument()
        sel = @editor.getSelectionRange()

        doc.insert({row: sel.end.row, column: sel.end.column}, text)

BbCodeEdit.addPlugin(new TextPlugin())
BbCodeEdit.addPlugin(new TitlePlugin())
BbCodeEdit.addPlugin(new ListPlugin())
BbCodeEdit.addPlugin(new EmailPlugin())
BbCodeEdit.addPlugin(new UrlPlugin())
BbCodeEdit.addPlugin(new PostPlugin())
BbCodeEdit.addPlugin(new TablePlugin())
BbCodeEdit.addPlugin(new VideoPlugin())

JqueryUtils.declarePlugin namePlugin,
    allowedMethods: ['destroy']
    initCallback: (element, opts) ->
        opts.$container = jq(element)
        BbCodeEdit.fromDom(opts)

module.exports = BbCodeEdit