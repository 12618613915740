Plugin = require './Plugin'

class TitlePlugin extends Plugin
    getName: () -> "title"
    initToolbar: (bbCodeEdit) ->
        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({label: 'Titre'}, [
            {label: "H1", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('h1')},
            {label: "H2", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('h2')},
            {label: "H3", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('h3')},
            {label: "H4", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('h4')}
            {label: "H5", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('h5')}
            {label: "H6", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('h6')}
        ]))

        bbCodeEdit.$toolbar.append(bbCodeEdit.toolbarBuilder.createDropdown({label: 'Gros texte'}, [
            {label: "Big1", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('big1')},
            {label: "Big2", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('big2')},
            {label: "Big3", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('big3')},
            {label: "Big4", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('big4')}
            {label: "Big5", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('big5')}
            {label: "Big6", clickCallback: (bbCodeEdit) -> bbCodeEdit.wrapSelectionWithTag('big6')}
        ]))

module.exports = TitlePlugin