// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Validator from "../../validator/validator.bs.js";
import * as BBCodeEdit from "./BBCodeEdit.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Utils_react from "../../utils/Utils_react.bs.js";
import * as Utils_promise from "../../utils/Utils_promise.bs.js";
import * as Utils_unicode from "../../utils/Utils_unicode.bs.js";
import * as Form$ReasonForm from "@maarekj/reason-form/lib/es6/src/form.bs.js";
import * as React_bootstrap from "../../vendor/React_bootstrap.bs.js";
import * as Wrap$ReasonForm from "@maarekj/reason-form/lib/es6/src/wrap.bs.js";
import * as Field$ReasonForm from "@maarekj/reason-form/lib/es6/src/Field.bs.js";
import * as Caml_chrome_debugger from "bs-platform/lib/es6/caml_chrome_debugger.js";
import * as Component_form_render from "../Component_form_render.bs.js";
import * as ReactUtils from "genpages-js/lib/reactUtils";

function renderElement(prim, prim$1) {
  ReactUtils.renderElement(prim, prim$1);
  return /* () */0;
}

var Value = { };

var src = Field$ReasonForm.createField("src", (function (v) {
        return v.src;
      }), (function (v, values) {
        return {
                src: v,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var legend = Field$ReasonForm.createField("legend", (function (v) {
        return v.legend;
      }), (function (v, values) {
        return {
                src: values.src,
                legend: v,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var copyright = Field$ReasonForm.createField("copyright", (function (v) {
        return v.copyright;
      }), (function (v, values) {
        return {
                src: values.src,
                legend: values.legend,
                copyright: v,
                width: values.width,
                float: values.float,
                clickable: values.clickable
              };
      }));

var width = Field$ReasonForm.createField("width", (function (v) {
        return v.width;
      }), (function (v, values) {
        return {
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: v,
                float: values.float,
                clickable: values.clickable
              };
      }));

var clickable = Field$ReasonForm.createField("clickable", (function (v) {
        return v.clickable;
      }), (function (v, values) {
        return {
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: values.float,
                clickable: v
              };
      }));

var $$float = Field$ReasonForm.createField("float", (function (v) {
        return v.float;
      }), (function (v, values) {
        return {
                src: values.src,
                legend: values.legend,
                copyright: values.copyright,
                width: values.width,
                float: v,
                clickable: values.clickable
              };
      }));

function onValidate(form) {
  var values = Form$ReasonForm.getValues(form);
  var partial_arg = /* `field */Caml_chrome_debugger.polyVar("field", [
      9671866,
      src
    ]);
  var partial_arg$1 = values.src;
  return (function (eta) {
              var param = undefined;
              var param$1 = eta;
              return Validator.optionStringIsRequired(partial_arg$1, partial_arg, param, param$1);
            })(form);
}

var initialForm = {
  src: undefined,
  legend: undefined,
  copyright: undefined,
  width: undefined,
  float: undefined,
  clickable: true
};

var initializedForm = Form$ReasonForm.initializeForm(initialForm, undefined, undefined, undefined, undefined, undefined, onValidate, /* () */0);

var Fields = {
  Value: Value,
  src: src,
  legend: legend,
  copyright: copyright,
  width: width,
  clickable: clickable,
  $$float: $$float,
  onValidate: onValidate,
  initialForm: initialForm,
  initializedForm: initializedForm
};

function BBCode_ImagePlugin$Button(Props) {
  var bbcode = Props.bbcode;
  var show = Utils_react.useToggleShow(false);
  var wrap = React.useMemo((function () {
          return Wrap$ReasonForm.make(undefined, initializedForm);
        }), ([]));
  var onSubmit = React.useCallback((function (form) {
          var match = Form$ReasonForm.getValues(form);
          var $$float = match.float;
          var width = match.width;
          var copyright = match.copyright;
          var legend = match.legend;
          var src = match.src;
          var attrs = src !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "src",
                  src
                ],
                /* [] */0
              ]) : /* [] */0;
          var attrs$1 = legend !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "legend",
                  legend
                ],
                attrs
              ]) : attrs;
          var attrs$2 = copyright !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "copyright",
                  copyright
                ],
                attrs$1
              ]) : attrs$1;
          var attrs$3 = width !== undefined ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "width",
                  width
                ],
                attrs$2
              ]) : attrs$2;
          var attrs$4 = $$float !== undefined ? (
              $$float >= -379319332 ? /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "float",
                      "right"
                    ],
                    attrs$3
                  ]) : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                    /* tuple */[
                      "float",
                      "left"
                    ],
                    attrs$3
                  ])
            ) : attrs$3;
          var attrs$5 = match.clickable ? attrs$4 : /* :: */Caml_chrome_debugger.simpleVariant("::", [
                /* tuple */[
                  "clickable",
                  "true"
                ],
                attrs$4
              ]);
          BBCodeEdit.insertTagAutoclosed(bbcode, "photo", attrs$5);
          Wrap$ReasonForm.dispatch(wrap, (function (param) {
                  return initializedForm;
                }));
          Curry._1(show.hide, /* () */0);
          return Utils_promise.resolveOk(/* () */0);
        }), /* tuple */[
        wrap,
        show
      ]);
  return React.createElement("div", {
              className: "btn-group btn-group-bbcode"
            }, React.createElement("button", {
                  className: "btn btn-default btn-bbcode",
                  onClick: show.showWithPrevent
                }, React.createElement("i", {
                      className: "fa fa-photo"
                    }), React.createElement("span", undefined, Utils_react.ste(Utils_unicode.nbsp + "Image"))), React.createElement(React_bootstrap.Modal.make, {
                  onHide: show.hide,
                  show: show.value,
                  children: React.createElement(Component_form_render.Form.make, {
                        wrap: wrap,
                        className: "modal-content",
                        onSubmit: onSubmit,
                        render: React.createElement(React.Fragment, undefined, React.createElement("div", {
                                  className: "modal-header"
                                }, React.createElement("button", {
                                      className: "close",
                                      onClick: show.hideWithPrevent
                                    }, Utils_unicode.timesElement), React.createElement("h4", {
                                      className: "modal-title"
                                    }, Utils_react.ste("Ajouter une image"))), React.createElement("div", {
                                  className: "modal-body"
                                }, React.createElement(Component_form_render.Row.make, {
                                      label: "Url de l\'image",
                                      input: React.createElement(Component_form_render.Input.make, {
                                            wrap: wrap,
                                            toText: (function (v) {
                                                return Belt_Option.getWithDefault(v, "");
                                              }),
                                            fromText: (function (v) {
                                                var match = v === "";
                                                if (match) {
                                                  return ;
                                                } else {
                                                  return v;
                                                }
                                              }),
                                            field: src
                                          }),
                                      wrap: wrap,
                                      field: src
                                    }), React.createElement(Component_form_render.Row.make, {
                                      label: "Légende",
                                      input: React.createElement(Component_form_render.Input.make, {
                                            wrap: wrap,
                                            toText: (function (v) {
                                                return Belt_Option.getWithDefault(v, "");
                                              }),
                                            fromText: (function (v) {
                                                var match = v === "";
                                                if (match) {
                                                  return ;
                                                } else {
                                                  return v;
                                                }
                                              }),
                                            field: legend
                                          }),
                                      wrap: wrap,
                                      field: legend
                                    }), React.createElement(Component_form_render.Row.make, {
                                      label: "Copyright",
                                      input: React.createElement(Component_form_render.Input.make, {
                                            wrap: wrap,
                                            toText: (function (v) {
                                                return Belt_Option.getWithDefault(v, "");
                                              }),
                                            fromText: (function (v) {
                                                var match = v === "";
                                                if (match) {
                                                  return ;
                                                } else {
                                                  return v;
                                                }
                                              }),
                                            field: copyright
                                          }),
                                      wrap: wrap,
                                      field: copyright
                                    }), React.createElement(Component_form_render.Row.make, {
                                      label: "Largeur",
                                      input: React.createElement(Component_form_render.Input.make, {
                                            wrap: wrap,
                                            toText: (function (v) {
                                                return Belt_Option.getWithDefault(v, "");
                                              }),
                                            fromText: (function (v) {
                                                var match = v === "";
                                                if (match) {
                                                  return ;
                                                } else {
                                                  return v;
                                                }
                                              }),
                                            field: width
                                          }),
                                      wrap: wrap,
                                      field: width
                                    }), React.createElement(Component_form_render.Row.make, {
                                      label: "Image flottante",
                                      input: React.createElement(Component_form_render.Choice.make, {
                                            wrap: wrap,
                                            choices: /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                {
                                                  value: undefined,
                                                  string: "none",
                                                  label: "Non flottante"
                                                },
                                                /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                    {
                                                      value: /* left */-944764921,
                                                      string: "left",
                                                      label: "Flottante à gauche"
                                                    },
                                                    /* :: */Caml_chrome_debugger.simpleVariant("::", [
                                                        {
                                                          value: /* right */-379319332,
                                                          string: "right",
                                                          label: "Flottante à droite"
                                                        },
                                                        /* [] */0
                                                      ])
                                                  ])
                                              ]),
                                            field: $$float
                                          }),
                                      wrap: wrap,
                                      field: $$float
                                    }), React.createElement(Component_form_render.Row.make, {
                                      input: React.createElement(Component_form_render.BoolCheckbox.make, {
                                            wrap: wrap,
                                            label: "Cliquable ?",
                                            field: clickable
                                          }),
                                      wrap: wrap,
                                      field: clickable
                                    })), React.createElement("div", {
                                  className: "modal-footer"
                                }, React.createElement("button", {
                                      className: "btn btn-default",
                                      onClick: show.hideWithPrevent
                                    }, Utils_react.ste("Fermer")), React.createElement(Component_form_render.SubmitButton.make, {
                                      wrap: wrap,
                                      text: "Ajouter l\'image",
                                      submittingText: "Ajout de l\'image"
                                    })))
                      })
                }));
}

var Button = {
  FormRender: /* alias */0,
  make: BBCode_ImagePlugin$Button
};

function make(param) {
  return {
          getName: (function () {
              return "image";
            }),
          initToolbar: (function (bbcode) {
              var group = document.createElement("div");
              BBCodeEdit.toolbar(bbcode).appendChild(group);
              var prim = React.createElement(BBCode_ImagePlugin$Button, {
                    bbcode: bbcode
                  });
              ReactUtils.renderElement(group, prim);
              return /* () */0;
            })
        };
}

var Dom = /* alias */0;

var $$Element = /* alias */0;

var Modal = /* alias */0;

var ste = Utils_react.ste;

var preventCallback = Utils_react.preventCallback;

var nbsp = Utils_unicode.nbsp;

var timesElement = Utils_unicode.timesElement;

var isNone = Belt_Option.isNone;

export {
  Dom ,
  $$Element ,
  Modal ,
  ste ,
  renderElement ,
  preventCallback ,
  nbsp ,
  timesElement ,
  isNone ,
  Fields ,
  Button ,
  make ,
  
}
/* src Not a pure module */
